import axios from "axios";
import { Loading } from "element-ui";
export function request(config) {
  // 创建axios的实例
  const instance = axios.create({
    baseURL:
      // "https://134.175.222.95:8083/api",
      // "http://192.168.14.67:8083/api",
      '/api',
      timeout: 600000
  });
  return new Promise((resolve, reject) => {
  // 请求拦截
  instance.interceptors.request.use(
    (config) => {
      //增加loding动画
      // loadinginstance = Loading.service({
      //   fullscreen:true
      // })

      //如果存在token就将token加入请求头中
      if (localStorage.token) {
        config.headers.Token = localStorage.token;
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  //关闭loading动画
  // loadinginstance = Loading.service({
  //   fullscreen:false
  // })

  //响应拦截
  instance.interceptors.response.use(
    (response) => {
      return response.data
    },
    (error) => Promise.reject(error) // 返回接口返回的错误信息
  );

  // 3.发送真正的网络请求
  // 请求完成处理
  instance(config)
  .then((res) => {
    if (res.code !== 200) {
      reject(res)
    } else {
      resolve(res)
    }
  })
  .catch((error) => {
    reject(error)
  })
})}
