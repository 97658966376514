import { request } from "./request";

//登录
export function login(data) {
  return request({
    url: "/login",
    method: "post",
    data
  })
}
// 反馈
export function addFeedback(data) {
  return request({
    url: `/user/addFeedback`,
    method: "post",
    data
  })
}
export function register(data) {
  return request({
    url: `/register`,
    method: "post",
    data
  })
}
//注册时获取验证码
export function mailVerify(data) {
  return request({
    url: `/sendverification/${data}`,
    method: "get"
  });
}

//注册时进行验证码验证
export function codeCheck(mail, code) {
  return request({
    url: "/codeCheck",
    method: "post",
    data: {
      mail: mail,
      code,
      code
    }
  });
}
//修改密码时进行验证码验证
export function verfifyemail(email) {
  return request({
    url: `/verfifyemail/${email}`,
    method: "get",
  });
}

// 重置密码
export function resetpassword(data) {
  return request({
    url: "/resetpassword",
    method: "post",
    data
  });
}
//个人信息修改
export function changeInfo(user_id, form) {
  return request({
    url: "/",
    method: "put",
    param: user_id,
    data: form
  });
  // form:{
  //         name:
  // password:
  // tel:
  // email:
  // }
}
