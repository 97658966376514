<template>
  <footer id="xh_footer" class="buttom">
    <div class="buttom_inner">
      <nav class="directory">
        <div class="directory_inner">
          <div>
            <label>
              <h3><a href="javascript:;">关于薪火</a></h3>
            </label>
            <a href="javascript:;">薪火简介</a>
            <a href="javascript:;">组织架构</a>
            <a href="javascript:;">发展历程</a>
            <a href="javascript:;">公益合作</a>
          </div>
          <div>
            <label>
              <h3><a href="javascript:;">志愿天地</a></h3>
            </label>
            <a href="javascript:;">报名参与</a>
            <a href="javascript:;">志愿活动推荐</a>
            <a href="javascript:;">往期活动</a>
            <a href="javascript:;">志愿者风采</a>
          </div>
          <div>
            <label>
              <h3><a href="javascript:;">薪⽕空间</a></h3>
            </label>
            <a href="javascript:;">薪火要闻</a>
            <a href="javascript:;">感人时刻</a>
            <a href="javascript:;">成员风采</a>
          </div>
          <div>
            <label>
              <h3><a href="javascript:;">加⼊薪火</a></h3>
            </label>
            <a href="javascript:;">事务部</a>
            <a href="javascript:;">技术部</a>
            <a href="javascript:;">文宣部</a>
            <a href="javascript:;">外联部</a>
            <a href="javascript:;">内务部</a>
          </div>
          <div>
            <label>
              <h3><a href="javascript:;">关注我们</a></h3>
            </label>
            <a href="javascript:;">公众号</a>
            <a href="javascript:;">官方QQ号</a>
            <a href="javascript:;">志愿云</a>
          </div>
          <div>
            <label>
              <h3><a href="javascript:;">联系我们</a></h3>
            </label>
            <a href="javascript:;">志愿者联系</a>
            <a href="javascript:;">志愿组织联系</a>
          </div>
        </div>
      </nav>
      <section>
        <div class="site-info">
          <a href="javasrcipt:;">© 2023 薪火志愿者协会技术部</a>
        </div>
        <div class="site-legal">
          <a href="https://beian.miit.gov.cn/"
            target="_blank">渝ICP备2023014616号-1</a>
          <a href="javasrcipt:;">
            <img
              src="@/assets/img/beian.png"
              alt=""
              style="width: 14px; height: 14px; margin-right: 4px"
            />渝公网安备XXXXXXXXXXXX号</a
          >
          <a href="javasrcipt:;">渝ICP证XXXX-XXXXX</a>
          <a href="https://www.cqjbzx.cn/" target="_blank"
            >重庆市互联网违法和不良信息举报中心</a
          ><a href="https://www.12377.cn/" target="_blank"
            >中国互联网违法和不良信息举报中心</a
          >
        </div>
      </section>
    </div>
  </footer>
</template>                                                                                                
<script>
export default {
  name: "buttomNav",
};
</script>

<style lang="scss" scoped>
#xh_footer {
  // 主体内容永远局中
  padding-left: calc(100vw - 100%);
}

.buttom {
  position: relative;
  min-width: 1000px;
  border-top: 1px solid rgba(var(--header-bottom-rgb), 1);
  background-color: var(--color-header-background);

  .buttom_inner {
    margin: 0 auto;
    width: 1000px;
    padding: 15px;
    color: var(--color-text);

    .directory {
      text-align: left;
      border-bottom: 1px solid hsla(0, 5%, 23%, 0.1);
      padding: 0 0 16px;
      margin: 0 0 16px;

      .directory_inner {
        display: flex;
        flex-wrap: wrap;
        max-width: 960px;

        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 25%;

          > label {
            padding: 4px;
          }

          h3 {
            padding-bottom: 8px;

            a {
              font-size: 16px;
            }
          }

          > a {
            padding: 4px;
          }
          a:hover {
            color: var(--color-footer-high);
          }
        }
      }
    }

    .site-legal {
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      font-size: 12px;
      padding-top: 16px;
      width: 100%;

      > a {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 16px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        padding-bottom: 5px;
      }
    }
  }
}
</style>