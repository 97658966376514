<template>
  <div id="xh_header" class="header">
    <nav class="headerContainer">
      <!-- 头部左侧区域 -->
      <div class="left">
        <!-- logo区域 -->
        <div class="logo">
          <router-link to="/home">
            <img src="@/assets/img/xh_logo.png" alt=""
          /></router-link>
        </div>
        <!-- 官网名称区域 -->
        <div class="name">
          <span>薪火志愿者协会</span>
        </div>
      </div>
      <!-- 头部右侧区域 -->
      <div class="right">
        <!-- 导航栏区域 -->
        <ul class="navBar">
          <li @click="toIndex" :class="this.$route.path=='/home'?'isActive':''">
            <span >首页</span>
          </li>
          <!-- <li>
            <span>关于薪火</span>
          </li> -->
         
          <li @click="toSpace" :class="this.$route.path=='/space'?'isActive':''">
            <span >薪火空间</span>
          </li>
          <li @click="toHelp" :class="this.$route.path=='/contactUs'?'isActive':''">
            <span >帮助</span>
          </li>
          <li @click="toDonate" :class="this.$route.path=='/homegggggg'?'isActive':''">
            <span >赞助我们</span>
          </li>
          <li v-if="isLogin">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ username
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li @click="login">
            <span  class="submitBtn" v-if="!isLogin">登录</span>
          </li>
          <li @click="toDocument" :class="this.$route.path=='/document'?'isActive':''">
            <span>薪火文档</span>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "headerBar",
  mounted() {
    if (localStorage.getItem("token")) {
      this.username = localStorage.getItem("username");
      this.isLogin = true;
    }
    this.$bus.$on("changeLoginStatus", (msg) => {
      this.username = msg;
      this.isLogin = true;
    });
  },
  data() {
    return {
      isLogin: false,
      username: "",
    };
  },
  methods: {
    // 去文档页面
    toDocument(){
      this.$router.push({
        path:"/document"
      })
    },
    /* 去首页*/
    toIndex(){
      this.$router.push({
        path:"/home"
      })
    },
    toSpace(){
      this.$router.push({
        path:"/space"
      })
    },
    toHelp(){
      this.$router.push({
        path:"/contactUs"
      })
    },
    toDonate(){
      this.$router.push({
        path:"/safsa"
      })
    },
    /*退出登录*/
    logout() {
      this.username = "";
      localStorage.setItem("token", "");
      localStorage.setItem("username", "");
      this.$message.success("退出登录成功！");
      this.isLogin = false;
    },
    /*处理登录*/
    login() {
      this.$bus.$emit("toLogin", true);
    },
  },
};
</script>

<style lang="scss" scoped>
#xh_header {
  z-index: 1;
  // 主体内容永远局中
  padding-left: calc(100vw - 100%);
}

.header {
  // 居中布局
  width: 100%;
  height: 40px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-width: 1000px;
  border-bottom: 1px solid rgba(var(--header-bottom-rgb), 1);
  background-color: var(--color-header-background);

  .headerContainer {
    display: flex;
    justify-content: space-between;
    min-width: 1000px;
    max-width: 1152px;
    width: 100%;
    flex-wrap: nowrap;

    .left {
      display: flex;
      align-self: center;
      flex-wrap: nowrap;
      height: 40px;

      .logo {
        margin-right: 40px;
        img {
          
          height: 100%;
        }
      }

      .name {
        font-size: 1.875rem;
        font-weight: 500;
        line-height: 40px;
        text-align: center;
        color: var(--color-header-high);
      }
    }

    .right {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      .navBar {
        display: flex;
        align-items: center;
        height: 40px;

        li {
          margin-right: 20px;
          padding: 0 5px;
          line-height: 40px;
          text-align: center;
          font-size: 1.05rem;
        }

        li:hover {
          transform: scale(1.1);
          overflow: hidden;
          cursor: pointer;
          background-color: var(--color-header-high);
          border-radius: 2px;
          span {
            color: #409EFF;
          }
        }
        .isActive {
          border-bottom: 2px solid var(--color-header-high);
          span {
            color: var(--color-header-high);
            color: #409EFF;
          }
        }
      }
    }
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
