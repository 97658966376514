<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view></router-view>
    <Login/>
    <Register/>
    <forgetPas />
  </div>
</template>

<script>
import Login from './views/user/login.vue';
import Register from './views/user/register.vue';
import forgetPas from './views/user/forgetPas.vue';
export default {
  components: { Login, Register,forgetPas },
  name: "app",
  mounted() {
    this.IsPC();
  },
   methods: {
    // 判断PC端还是移动端
    IsPC() {
      let userAgentInfo = navigator.userAgent;
      let Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }

      if (!flag) {
        this.$router.replace("pc");
      } else {
        this.$router.replace("home");
      }
    },
  },
};
</script>

<style>
</style>
