import Vue from "vue";
import {
  Message,
  Row,
  Col,
  Carousel,
  CarouselItem
} from "element-ui";

Vue.use(Row);
Vue.use(Col);
Vue.use(Carousel);
Vue.use(CarouselItem);

Vue.prototype.$message = Message;
