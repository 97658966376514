<template>
    <el-dialog
      :visible.sync="forgetPasDialog"
      width="30%"
      :before-close="handleClose"
      title="忘记密码"
    >
      <div class="all">
        <!-- <img src="../../assets/img/register_poster.png" alt="忘记密码" /> -->
  
        <el-form
          label-position="left"
          label-width="100px"
          :model="formLabelAlign"
          :rules="rules"
          ref="userInfo"
          class="registerForm"
        >
          
          <el-form-item label="邮箱：" prop="email">
            <el-input v-model="formLabelAlign.email"></el-input>
          </el-form-item>
          <div class="code">
            <el-form-item label="验证码：" prop="verification">
              <el-input v-model="formLabelAlign.verification"></el-input>
            </el-form-item>
  
            <el-button disabled v-if="second > 0" class="codeBtn">{{
              second
            }}</el-button>
            <el-button @click="getCode" class="codeBtn" type="primary" v-else
              >获取验证码</el-button
            >
          </div>
          <el-form-item label="密码：" prop="password">
            <el-input v-model="formLabelAlign.password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="passwordConfirm">
            <el-input v-model="formLabelAlign.passwordConfirm"></el-input>
          </el-form-item>
        </el-form>
  
        <br />
  
        <div class="submitBtn">
          <el-button @click="submitform" type="primary" style="width:150px">修改密码</el-button>
        </div>
        <div class="LR2">
          <span></span><span @click="toLogin" style="color:#409EFF">已有帐号?点此登录></span>
        </div>
      </div>
    </el-dialog>
  </template>
  
  <script>
  import { verfifyemail, resetpassword } from "@/api/user";
  export default {
    name: "forgetPas",
    mounted() {
      console.log(8182);
      this.$bus.$on("toForgetPas", (msg) => {
        if (msg) {
          this.forgetPasDialog = true;
        }
      });
    },
    methods: {
      handleClose() {
        this.$refs["userInfo"].resetFields();
        this.forgetPasDialog = false;
      },
      // 获取验证码
      getCode() {
        this.$refs.userInfo.validateField("email", (valid) => {
          if (!valid) {  
            verfifyemail(this.formLabelAlign.email).then(()=>{
               this.second = 60;
            setInterval(() => {
              if (this.second > 0) {
                this.second--;
              }
            }, 1000);
  
            }).catch(({message})=>{
              this.$message.error(`${message}`)
            })
          }
        });
      },
      /*去登录页面*/
      toLogin() {
        this.forgetPasDialog = false;
        this.$bus.$emit("toLogin", true);
      },
      /*提交修改密码表单*/
      submitform() {
        this.$refs["userInfo"].validate((valid) => {
          if (valid) {
            resetpassword(this.formLabelAlign)
              .then((res) => {
                this.forgetPasDialog = false;
                this.$message.success("修改密码成功");
                this.formLabelAlign = {
                  password: "",
                  passwordConfirm: "",
                  verification: "",
                  email: "",
                };
                this.$bus.$emit("toLogin", true);
              })
              .catch((error) => {
                this.$message.error(error);
              });
          } else {
            return false;
          }
        });
      },
    },
    data() {
      // 密码的自定义检验
      var validatePass = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入密码"));
        } else {
          if (this.formLabelAlign.password !== "") {
            this.$refs.userInfo.validateField("passwordConfirm");
          }
          callback();
        }
      };
      // 再次输入密码的自定义检验
      var validatePass2 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== this.formLabelAlign.password) {
          callback(new Error("两次输入密码不一致!"));
        } else {
          callback();
        }
      };
      return {
        second: 0,
        forgetPasDialog: false,
        // 修改密码的表单
        formLabelAlign: {
          password: "",
          passwordConfirm: "",
          verification: "",
          email: "",
        },
        rules: {
          password: [
            { required: true, validator: validatePass, trigger: "blur" },
          ],
          passwordConfirm: [
            { required: true, validator: validatePass2, trigger: "blur" },
          ],
          verification: [
            { required: true, message: "请输入验证码", trigger: "blur" },
          ],
          email: [{ required: true, message: "请输入邮箱", trigger: "blur" },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
        },
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .registerForm{
    margin-top: 15px;
  }
  .LR2 {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
  }
  .LR2 span {
    color: lightslategray;
    cursor: pointer;
  }
  img {
    width: 100%;
  }
  .code {
    display: flex;
    justify-content: space-between;
    .codeBtn {
      width: 100px;
      height: 40px;
    }
  }
  .submitBtn {
    justify-content: center;
    display: flex;
  }
  </style>