import {request} from "./request";

//薪火要闻获取信息
export function getNews(data) {
  return request({
    url: '/news/listAll',
    method:'get',
    params: data
  })
}

//成员风采获取信息
export function getMember() {
  return request({
    method: 'get',
    url: '/member/getListAll'
  })
}

//志愿者风采获取信息
export function getVolunteer() {
  return request({
    url: '/volunteer/getListAll'
  })
}

//志愿活动获取
export function getActivity() {
  return request({
    url: '/activity'
  })
}
//薪火要闻详情
export function getDetail(id) {
  return request({
    url: `/news/details/${id}`,
    method: 'get'
  })
}
export function getFile(fileName) {
  return request({
    url: `/news/download/${fileName}`,
    method: 'get'
  })
}
// 用户报名志愿活动相关接口
// export function signUpActive(actChiId,actId,actTimesId
//   ) {
//   return request({
//     url: '/home/data',
//     data: {
//       type,
//       page
//     }
//   })
// }


