<template>
  <div>
    <el-dialog
      :visible.sync="loginDialog"
      width="30%"
      :before-close="handleClose"
    >
      <div class="all">
        <img src="../../assets/img/login_poster.png" alt="登录" />

        <el-form
          label-position="left"
          label-width="100px"
          :model="formLabelAlign"
          :rules="rules"
          ref="userInfo"
          class="loginForm"
        >
          <el-form-item label="账号:" prop="email">
            <el-input
              v-model="formLabelAlign.email"
              placeholder="薪火账号/邮箱/手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="password">
            <el-input v-model="formLabelAlign.password"></el-input>
          </el-form-item>
        </el-form>

        <br />
        <div class="LR1"><span> </span><span @click="toForgetPas" style="color:#409EFF">忘记密码</span></div>
        <br />

       
        <div class="submitBtn"><el-button @click="login" type="primary" style="width:150px">登录</el-button></div>
        <br />
        <br />
        <div class="LR2">
          <span></span><span id="register" @click="toRegister" style="color:#409EFF">没有帐号？点此注册></span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { login } from "@/api/user";
export default {
  name: "login",
  mounted() {
    this.$bus.$on("toLogin", (msg) => {
      if (msg) {
        this.loginDialog = true;
      }
    });
  },
  data() {
    return {
      loginDialog: false,
      formLabelAlign: {
        password: "",
        email: "",
      },
      rules: {
        email: [{ required: true, message: "请输入邮箱", trigger: "change" },
        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    // 去忘记密码界面
    toForgetPas(){
      this.loginDialog = false;
      this.$bus.$emit("toForgetPas", true);
    },
    /*去注册页面*/
    toRegister() {
      this.loginDialog = false;
      this.$bus.$emit("toRegister", true);
    },
    /*登录*/
    login() {
      this.$refs["userInfo"].validate((valid) => {
        if (valid) {
          login(this.formLabelAlign)
            .then((res) => {
              this.loginDialog = false;
              this.$message.success("登录成功！");
              this.$refs["userInfo"].resetFields();
              localStorage.setItem("token",res.data.token)
              localStorage.setItem("username",res.data.username)
              this.$bus.$emit("changeLoginStatus", res.data.username);
            })
            .catch((error) => {
              this.$message.error(`${error.message}`);
            });
        }
      });
    },
    handleClose() {
      this.$refs["userInfo"].resetFields();
      this.loginDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.loginForm{
  margin-top: 15px;
}

img {
  width: 100%;
}
.LR2{
  display: flex;
  justify-content: space-between;
}
.LR2 span{
  color:lightslategray;
  cursor: pointer;
}
.submitBtn{
  justify-content: center;
  display: flex;
}
</style>