import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api/index.js";
import ElementUI from "element-ui";
import "./plugins/element.js";

// 引入全局样式
import "./assets/css/globle.css";
import "./assets/css/base.css";

// 注册全局组件
import HeaderBar from "@/components/common/headerBar/headerBar.vue";
import FeedBack from "@/components/common/feedback/feedback.vue";
import FooterBar from "@/components/common/footerBar/footerBar.vue";
import login from "@/views/user/login"
import register from "@/views/user/register"
import forgetPas from "@/views/user/forgetPas";

Vue.component("HeaderBar", HeaderBar);
Vue.component("FeedBack", FeedBack);
Vue.component("FooterBar", FooterBar);
Vue.component("login",login)
Vue.component("register",register)
Vue.component("forgetPas",forgetPas)

Vue.use(ElementUI);
// 全局请求变量$api
Vue.prototype.$api = api;

Vue.config.productionTip = false;



new Vue({
  router,
  store,
  beforeCreate(){
    Vue.prototype.$bus=this
  },
  render: (h) => h(App)
}).$mount("#app");
