import Vue from "vue";
import VueRouter from "vue-router";

const Home = () => import("@/views/home/home");
const Login = () => import("@/views/user/login");
const Register = () => import("@/views/user/register");
const Volunteer = () => import("@/views/volunteer/volunteer");
const Space = () => import("@/views/space/space");
const FollowUs = () =>import("@/views/followUs/followUs");
const ContactUs = () =>import("@/views/contactUs/contactUs");
const Pc = () =>import("../pc.vue")
const NewDetail = () =>import("@/views/space/components/components/newDetail")
const Document = () => import("@/views/document");
const DocumentDetail = () => import("@/views/document/detail");
const ForgetPas = () => import("@/views/user/forgetPas")
Vue.use(VueRouter);
const routes = [
  {
    path: "",
    redirect: "/home"
  },
  {
    path: "/home",
    component: Home
  },
  {
    path:"/volunteer",
    component:Volunteer
  },
  {
    path:"/space",
    component:Space
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/register",
    component: Register
  },
  {
    path: "/followus",
    component: FollowUs
  },
  {
    path: "/contactus",
    component: ContactUs
  },
  {
    path: "/pc",
    component: Pc
  },{
    path: "/new/detail",
    component :NewDetail
  },{
    path: "/document",
    component: Document
  },{
    path: "/document/detail",
    component: DocumentDetail
  },{
    path: "/forgetPas",
    component: ForgetPas
  }
];

const router = new VueRouter({
  routes
});

export default router;
