<template>
  <div id="xh_feedback">
    <a href="javascript:;" class="ButtonContainer" title="问题反馈">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="1em"
        height="1em"
        fill="currentColor"
        class="Icon"
      >
        <path
          fill-rule="evenodd"
          d="M20.494 16.249a9.5 9.5 0 01-8.491 5.25 9.38 9.38 0 01-3.939-.855l-4.376.838a1 1 0 01-1.17-1.17l.838-4.376a9.377 9.377 0 01-.856-3.937 9.498 9.498 0 015.25-8.492A9.378 9.378 0 0112 2.5l.555.001c4.825.266 8.678 4.119 8.945 8.999v.497a9.381 9.381 0 01-1.006 4.252zM4.76 19.24l3.252-.622a1 1 0 01.64.09A7.382 7.382 0 0012 19.5a7.5 7.5 0 006.706-4.147A7.386 7.386 0 0019.5 12l.002-.445c-.21-3.807-3.25-6.847-7.002-7.055h-.502a7.385 7.385 0 00-3.35.794A7.5 7.5 0 004.5 12.002a7.38 7.38 0 00.793 3.347 1 1 0 01.09.639L4.76 19.24zM7.9 9.35c0 .718.58 1.3 1.294 1.3h.012c.715 0 1.294-.582 1.294-1.3 0-.718-.58-1.3-1.294-1.3h-.012c-.715 0-1.294.582-1.294 1.3zm6.893 1.3a1.297 1.297 0 01-1.293-1.3c0-.718.58-1.3 1.293-1.3h.014c.714 0 1.293.582 1.293 1.3 0 .718-.58 1.3-1.294 1.3h-.012zm.915 3.708a5.244 5.244 0 01-7.416 0 1 1 0 111.414-1.415 3.244 3.244 0 004.588 0 1 1 0 011.414 1.415z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: "feedback",
};
</script>

<style lang="scss" scoped>
#xh_feedback {
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 1;
  .ButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    box-shadow: 0 2px 8px rgba(var(--box-shadow-rgb), 0.1),
      0 1px 2px rgba(var(--box-shadow-rgb), 0.1);
    border-radius: 8px;
    cursor: pointer;
    background-color: var(--feedback-active-bgc);
    .Icon {
      font-size: 24px;
      color: var(--color-header-high);
    }
  }
}
</style>
