import {request} from "./request";


//志愿者评论
// commentForm:
// {user_id
// activity_id
// parent_id
// content}

//志愿者评论
export function comment(commentForm) {
  return request({
    url: '/comment',
    data: commentForm
  })
}






